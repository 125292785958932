(function ($) {
  Drupal.behaviors.tabbedBlockV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $template = $('.js-tabbed-block-v1', context);
      var $blockTabs = $('.js-tabbed-block__tab', $template);
      var $sitecontent = $('.site-content', context);
      var $element = false;

      var _scrollToAnchorLinkOpened = function () {
        var hash = window.location.hash;
        var $anchorLinkSection = $(hash, context);
        var headerHeight = $('.site-header__wrapper', context).height();
        var $index = $();
        var $parent = $();
        var $content = $();
        var $blockContents = $();
        if (hash) {
          if ($anchorLinkSection.length > 0) {
            $(window).scrollTop($anchorLinkSection.offset().top - headerHeight);
            $index = $anchorLinkSection.index();
            $parent = $anchorLinkSection.parent();
            $content = $parent.siblings('.js-tabbed-block__content');
            $blockContents = $('.js-tabbed-block__content-item', $content);
            $blockTabs.attr('aria-expanded', 'false');
            $anchorLinkSection.attr('aria-expanded', 'true');
            $blockContents.attr('aria-hidden', 'true');
            $blockContents.eq($index).attr('aria-hidden', 'false');
          }
        }
      };
      _scrollToAnchorLinkOpened();
      $(window).on('hashchange', function () {
        _scrollToAnchorLinkOpened();
      });

      $blockTabs.once().on('click', function () {
        var $this = $(this);
        var $index = $this.index();
        var $parent = $this.parent();
        var $content = $parent.siblings('.js-tabbed-block__content');
        var $blockContents = $('.js-tabbed-block__content-item', $content);

        $blockTabs.attr('aria-expanded', 'false');
        $this.attr('aria-expanded', 'true');
        $blockContents.attr('aria-hidden', 'true');
        $blockContents.eq($index).attr('aria-hidden', 'false');
      });
    }
  };
})(jQuery);
